// @ts-nocheck
/* eslint-enable */
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

let bodymovin;

if (__BROWSER__) {
  bodymovin = require('app/client/bodymovin_light');
}

class Animation extends React.Component {
  static propTypes = {
    animationData: PropTypes.object.isRequired,
    isInteractive: PropTypes.bool,
    playTimer: PropTypes.number,
    shouldAutoplay: PropTypes.bool,
    shouldLoop: PropTypes.bool,
    shouldUseLastFrame: PropTypes.bool, // used for favorite icon; last frame is filled heart =)
    isPlaying: PropTypes.bool,
  };

  static defaultProps = {
    isInteractive: false,
    shouldAutoplay: false,
    shouldLoop: false,
    shouldUseLastFrame: false,
    isPlaying: true,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { animationData, isInteractive, playTimer, shouldAutoplay, shouldLoop, shouldUseLastFrame } = this.props;

    this.animation = bodymovin.loadAnimation({
      container: this.Container,
      renderer: 'svg',
      loop: shouldLoop,
      autoplay: shouldAutoplay,
      animationData,
    });

    if (isInteractive && shouldUseLastFrame) {
      this.animation.goToAndStop(this.animation.totalFrames - 1, true);
    }

    if (!shouldAutoplay && playTimer) {
      window.setTimeout(() => {
        this.animation.play();
      }, playTimer);
    }
  }

  componentDidUpdate(prevProps) {
    const { isInteractive, isPlaying, shouldUseLastFrame } = this.props;

    if (isInteractive && shouldUseLastFrame !== prevProps.shouldUseLastFrame) {
      this.handleAnimate(shouldUseLastFrame);
    }

    if (isPlaying !== prevProps.isPlaying) {
      if (isPlaying) {
        this.animation.play();
      } else {
        this.animation.pause();
      }
    }
  }

  handleAnimate = (shouldUseLastFrame) => {
    if (shouldUseLastFrame) {
      this.animation.play();
    } else {
      this.animation.goToAndStop(0);
    }
  };

  render() {
    return (
      <div className={cx('Animation', this.props.className)} ref={(ref) => (this.Container = ref)} aria-hidden="true" />
    );
  }
}

export default Animation;
